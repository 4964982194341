import React, { useState } from 'react'
import GeneralModalCrud from '../../utils/GeneralModalCrud';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { es } from 'date-fns/locale';
import DateFnsUtils from "@date-io/date-fns";
import ReactSelect from "react-select";
import { Button, Card, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from "moment";

const BillByProfessionalModal = ({
    HandleEnvoicedByProfessional,
    HandleCloseMakeBill,
    loadingSelect,
    socialWorks,
    getSocialWorksPlan,
    nameSocialWorkPlans,
    setIdPlanSelected,
    setDateFilterNew,
    dateFilterNew,
    profesionalList,
    setProfesionalSelected,
    IsSearchBillEnabled,
    searchBillOfProfessional,
    listDocumentsToBeBilled,
    isSearchDocs,
    IsAcceptEnabled,
    docsChequed,
    setDocsChequed,
    esViewInvoices = false
}) => {
    const setSocialWorkPlanId = (event) => {
        let idPlanSelected = event?.id;
        setIdPlanSelected(idPlanSelected === undefined ? null : idPlanSelected);
    };

    const handleClickCheck = (event, item) => {
        const isChecked = event.target.checked;

        setDocsChequed((prev) => {
            if (isChecked) {
                // Agregar el documentoId al array si está chequeado
                return [...prev, item.documentoId];
            } else {
                // Remover el documentoId del array si se deschequea
                return prev.filter((id) => id !== item.documentoId);
            }
        });
    };

    const TableRowData = ({ item }) => {
        return (
            <TableRow colSpan={6} key={item.documentoId}>
                <TableCell className="p-cell">
                    <Checkbox
                        onClick={(event) => handleClickCheck(event, item)}
                        checked={docsChequed.includes(item.documentoId)}
                    />
                </TableCell>
                <TableCell className="p-cell">
                    {moment(item.fechaPrimeraSesion).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell className="p-cell c-text-left">
                    {item.apellidoPaciente} {item.nombrePaciente}
                </TableCell>
                <TableCell className="p-cell">{item.documentoPaciente}</TableCell>
                <TableCell className="p-cell c-text-left">{item.obraSocial}</TableCell>
                <TableCell className="p-cell c-text-right">{item.numeroAutorizacion ?? "--"}</TableCell>
            </TableRow>
        );
    };

    return (
        <GeneralModalCrud
            titleOfModal="Facturar"
            openModal={true}
            HandleSubmit={HandleEnvoicedByProfessional}
            HandleCloseModal={HandleCloseMakeBill}
            disableAccept={IsAcceptEnabled()}
            textSubmit='Confirmar'
        >
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <div style={{ minWidth: 250, marginRight: 7 }}>
                        {esViewInvoices ?
                            <ReactSelect
                                placeholder='Obra Social...'
                                loading={loadingSelect.socialWork}
                                options={socialWorks}
                                onChange={getSocialWorksPlan}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (s) => ({ ...s, maxWidth: 250 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                            :
                            <ReactSelect
                                placeholder='Obra Social...'
                                loading={loadingSelect.socialWork}
                                options={socialWorks}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={getSocialWorksPlan}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (s) => ({ ...s, maxWidth: 250 }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        }
                    </div>

                    <ReactSelect
                        placeholder='Plan...'
                        loading={loadingSelect.socialWork}
                        options={nameSocialWorkPlans}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={setSocialWorkPlanId}
                        menuPortalTarget={document.body}
                        styles={{
                            control: (s) => ({ ...s, minWidth: 250 }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <KeyboardDatePicker
                        style={{ minWidth: 250, maxWidth: 250, marginRight: 7 }}
                        autoOk
                        className='size-date-picke-filter'
                        inputVariant='outlined'
                        InputAdornmentProps={{ position: 'end' }}
                        variant='inline'
                        name='month'
                        openTo='month'
                        views={['month', 'year']}
                        placeholder='Mes - Año'
                        value={dateFilterNew.date}
                        onChange={(date) => setDateFilterNew({ ...dateFilterNew, date: date })}
                    />

                    <ReactSelect
                        placeholder="Profesionales..."
                        options={profesionalList}
                        isLoading={loadingSelect.profesional}
                        onChange={(e) => setProfesionalSelected(prevState => ({ ...prevState, profesional: e }))}
                        menuPortalTarget={document.body}
                        styles={{
                            control: (s) => ({ ...s, minWidth: 250 }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                    />
                </div>
                <Button
                    disabled={IsSearchBillEnabled()}
                    primary
                    onClick={searchBillOfProfessional}
                    icon
                    labelPosition='left'
                    style={
                        { width: 150, justifySelf: "center" }
                    }
                >
                    <Icon name='search' />
                    Buscar
                </Button>

                {listDocumentsToBeBilled.length > 0 &&
                    <TableContainer component={Paper} id="color-letter-table-tasks">
                        <Table aria-label="spanning table">
                            <TableHead className="color-letter-inTable">
                                <TableRow>
                                    <TableCell className="letter-title-table not-padding-table-task">
                                    </TableCell>
                                    <TableCell className="letter-title-table">
                                        <b>Fecha</b>
                                    </TableCell>
                                    <TableCell className="letter-title-table c-text-left">
                                        <b>Paciente</b>
                                    </TableCell>
                                    <TableCell className="letter-title-table">
                                        <b>DNI</b>
                                    </TableCell>
                                    <TableCell className="letter-title-table c-text-left">
                                        <b>Obra Social</b>
                                    </TableCell>
                                    <TableCell className="letter-title-table c-text-right">
                                        <b>Nro. Autorización</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {listDocumentsToBeBilled && (
                                <TableBody>
                                    {listDocumentsToBeBilled.map((item) => (
                                        <TableRowData key={item.documentoId} item={item} />
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                }
                {listDocumentsToBeBilled && listDocumentsToBeBilled.length === 0 && isSearchDocs &&
                    <Grid.Row style={{ textAlign: "-webkit-center" }}>
                        <TableContainer component={Paper} id="color-letter-table-tasks">
                            <TableCell>
                                <h5>No se encontraron resultados</h5>
                            </TableCell>
                        </TableContainer>
                    </Grid.Row>
                }
            </MuiPickersUtilsProvider>
        </GeneralModalCrud>
    )
}

export default BillByProfessionalModal
