import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React, { useEffect } from 'react';
import ReactSelect from 'react-select';
import { Button, Icon, Card, CardContent, Dimmer, Loader } from "semantic-ui-react";
import NewModalBill from "./modals/CrudBill/NewModalBill";
import { ICrudBill } from "../../services/interfaces/Managment/ICrudBill";
import { IBill, ICrudBillFilterParams, IOpenModal, IValuesBill } from "../../api/Interfaces/managment/ICrudBill";
import { selectTypeFormat, typeOfActionForModal, typeOfModal } from "../../services/interfaces/utils/IUtilDeclaration";
import api from "../../api";
import { CreateBill, DeleteBill, EditBill, GenerateTxtSW, GetBills } from "../../services/services/CrudBill";
import ButtonBox from "../utils/ButtonBox";
import GeneralModalDelete from "../utils/GeneralModalDelete";
import { _DEFAULT_VALUES_BILL } from "./defaultValues";
import ViewProfesionalList from "./modals/CrudBill/ViewProfesionalList";
import { useSelector } from "react-redux";
import { SocialWorkEnum, StateBills, StateRebilled, StatusCode, TypeRoleNum } from "../../enums";
import GeneralModalCrud from "../utils/GeneralModalCrud";
import { FormatNumber } from "../utils/FormatInputs";
import moment from "moment";
import BillByProfessionalModal from "../period/modals/BillByProfessionalModal";
import { GetProfesionals } from "../../services/services/Users";
import { format } from "date-fns";
import { Menu, MenuItem, Grid, Paper, TableContainer } from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import HelpIcon from '@material-ui/icons/Help';

const validSocialWorks = new Set([
    SocialWorkEnum.omint,
    SocialWorkEnum.nobis,
    SocialWorkEnum.apross,
    SocialWorkEnum.amicos,
    SocialWorkEnum.sancorSalud,
    SocialWorkEnum.avalian,
    SocialWorkEnum.federada25DeJunio,
    SocialWorkEnum.medife,
    SocialWorkEnum.cajaDeAbogados,
    SocialWorkEnum.daspu,
    SocialWorkEnum.dasuten,
    SocialWorkEnum.iose,
    SocialWorkEnum.jerarquicoSalud,
    SocialWorkEnum.ossegSeguros,
    SocialWorkEnum.unimed,
    SocialWorkEnum.gilsaSrl,
])

const billingForm = { PorObraSocial: "Por Obra Social", porProfesional: "Por Profesional" };

const CrudBill: React.FC<ICrudBill> = ({ setDataToast }) => {
    const hasManagment = useSelector<any>(({ managment }) => managment.hasManagment);
    const loadForPage = 20;
    const [dataToRender, setDataToRender] = React.useState<IBill[]>([]);
    const [values, setValues] = React.useState<IValuesBill>(_DEFAULT_VALUES_BILL.values);
    const [filterObj, setFilterObj] = React.useState<ICrudBillFilterParams>(_DEFAULT_VALUES_BILL.filterObj);
    const [socialWorkList, setSocialWorkList] = React.useState<selectTypeFormat[]>([]);
    const [isLoaderActive, setIsLoaderActive] = React.useState(false);
    const [openModal, setOpenModal] = React.useState<IOpenModal>(_DEFAULT_VALUES_BILL.modals);
    const [page, setPage] = React.useState(1);
    const [pagesToRender, setPagesToRender] = React.useState(20);
    const [loaderSelect, setLoaderSelect] = React.useState({ socialWork: false, professional: false });

    //Facturar
    const [idSocialWork, setIdSocialWork] = React.useState(null);
    const [idPlanSelect, setIdPlanSelected] = React.useState(null);
    const [dateFilterNew, setDateFilterNew] = React.useState<any>({socialWorkId: null, date: null});
    const [profesionalSelected, setProfesionalSelected] = React.useState({profesional: {name: null, value: null}});
    const [docsChequed, setDocsChequed] = React.useState([]);
	const [nameSocialWorkPlans, setSocialWorksPlans] = React.useState([]);
    const [listDocumentsToBeBilled, setListDocumentsToBeBilled] = React.useState([]);
    const [isSearchDocs, setIsSearchDocs] = React.useState(false);
    const [profesionalList, setProfesionalList] = React.useState([]);
    const [menuBills, setMenuBills] = React.useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [modals, setModals] = React.useState({ makeBills: false, makeBillsByProfessional: false });

    useEffect(() => {
        (hasManagment && GetAllPromises());
    }, []);


    function GetAllPromises() {
        try {
            GetSocialWorkList();
            LoadInfoForTable();
            GetProfesionalList();
        }
        catch (err: any) {
            MessageNotification(err.message, "error");
        }
    }

    const GetSocialWorkList = React.useCallback(async () => {
        try {
            setLoaderSelect(prevState => ({...prevState, socialWork: true}));
            setIsLoaderActive(true);
            const { status, data } = await api.GetSocialWorkForSelect({ socialWorkId: filterObj.obraSocialId });
            if (status === 200) {
                const socialWorkList: selectTypeFormat[] = data.map((item) => ({
                    value: item.id,
                    label: `${item.nombre}`,
                })) ?? [];
                setSocialWorkList(socialWorkList);
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setLoaderSelect(prevState => ({...prevState, socialWork: false}));
            setIsLoaderActive(false);
        }
    }, []);

    const FilterComponents = () => (
        <Grid className="row-filterList content-filter-list">
            <div className="filter-input">
                <ReactSelect
                    placeholder="Obras Sociales..."
                    options={socialWorkList}
                    isLoading={loaderSelect.socialWork}
                    value={filterObj.obraSocial}
                    isClearable={true}
                    onChange={(e: any) => setFilterObj({
                        ...filterObj,
                        obraSocialId: e === null ? _DEFAULT_VALUES_BILL.filterObj.obraSocialId : e.value,
                        obraSocial: e
                    })}
                />
            </div>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    className="size-date-picke-filter filter-input"
                    clearable
                    style={{ margin: 8 }}
                    value={filterObj.fecha}
                    placeholder="Fecha"
                    onChange={(date: any) => setFilterObj({ ...filterObj, fecha: date })}
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
            <div className="filter-input">
                <ReactSelect
                    placeholder="Estados..."
                    options={StateBills}
                    value={filterObj.estadoFactura}
                    isClearable={true}
                    onChange={(e: any) => {
                        setFilterObj({
                            ...filterObj,
                            estadoFacturaId: e?.value ?? _DEFAULT_VALUES_BILL.filterObj.estadoFacturaId,
                            estadoFactura: e
                        })
                    }}
                />
            </div>
            <div className="filter-input">
                <ReactSelect
                    placeholder="Facturas..."
                    options={StateRebilled}
                    value={filterObj.refacturado}
                    isClearable={true}
                    onChange={(e: any) => {
                        setFilterObj({
                            ...filterObj,
                            refacturadoId: e?.value ?? _DEFAULT_VALUES_BILL.filterObj.refacturadoId,
                            refacturado: e
                        })
                    }}
                />
            </div>
            <Button
                primary
                icon
                labelPosition='left'
                onClick={()=>HandleSearch()}
                style={{marginLeft: "auto"}}
                >
                <Icon name='search' />
                Buscar
            </Button>
        </Grid>
    );

	//#region Facturar por Profesional
    const handleClick = (event) => {
        setMenuBills(event.currentTarget);
        setOpenMenu(true);
    };
    const handleClose = (action) => {
        setMenuBills(null);
        setOpenMenu(false);
        if (action === billingForm.PorObraSocial) {
            setModals({...modals, makeBills: true});
        }
        if (action === billingForm.porProfesional) {
            OpenModalSelected("open", "makeBillsByProfessional");
        }
    };
    //#endregion

    //#region Facturar por Obra Social
    async function HandleEnvoiced(){
		try{
			if (dateFilterNew.date === null){
				setDataToast({
					active: true,
					message: 'Debe ingresar Mes y Año',
					type: 'error',
				});
				return;
			}
			setIsLoaderActive(true);
			const {status} = await api.GenerateEnvoices({periodo: dateFilterNew.date, obraSocialId: dateFilterNew.socialWorkId});
			if (status === StatusCode.Ok){
				HandleCloseMakeBill();
				setDataToast({
					active: true,
					message: 'Facturas creadas con éxito.',
					type: 'success',
				});
				HandleSearch();
			}
		}catch(err: any){
			setDataToast({
				active: true,
				message: err?.response?.data?.errores ?? 'Acaba de ocurrir un error',
				type: 'error',
			});
		}finally{
			setIsLoaderActive(false);
		}
	};
    //#endregion

    //#region Facturar
    async function GetProfesionalList(){
		try {
            // setLoaderSelect({ professional: false });
            setLoaderSelect(prevState => ({...prevState, professional: true}));
			const { status, data } = await GetProfesionals({ rolId: TypeRoleNum.Professional });
			if (status === 200) {
				const userList: any = data?.results?.map((item) => ({
					value: item.id,
					label: `${item.apellido}, ${item.nombre}`,
				})) ?? [];
				setProfesionalList(userList);
			}
		} catch (err: any) {
			setDataToast({
				active: true,
				message: err.response.data.errores,
				type: 'error',
			});
		} finally { setLoaderSelect(prevState => ({...prevState, profesional: false})) }
	};
    function IsSearchBillEnabled() {
		return (idSocialWork === null || idPlanSelect === null || dateFilterNew.date === null || profesionalSelected.profesional.value === null);
	}
	function IsAcceptEnabled() {
		return docsChequed.length === 0;
	}
    const HandleEnvoicedByProfessional = async () => {
		try {
			setIsLoaderActive(true);
			if(IsSearchBillEnabled() || IsAcceptEnabled()) {
				setDataToast({
					active: true,
					message: 'Todos los campos son Obligatorios.',
					type: 'error',
				});
				return;
			}
			const body = {
				obraSocialId: idSocialWork,
				planObraSocialId: idPlanSelect,
				periodo: dateFilterNew.date ? format(dateFilterNew.date, 'yyyy-MM-dd') : null,
				documentosId: docsChequed
			}
            let mes = moment(dateFilterNew.date).format('MM');
			let anio = moment(dateFilterNew.date).format('YYYY');
			let mesAnio = `${mes}${anio}`;

			const { status } = await api.GenerateEnvoicesByDocuments(profesionalSelected.profesional.value, +mesAnio, body);
			if (status === StatusCode.Ok) {
				HandleCloseMakeBill();
				setDataToast({
					active: true,
					message: 'Factura creada con éxito.',
					type: 'success',
				});
				LoadInfoForTable();
			}
		} catch (err: any) {
			setDataToast({
				active: true,
				message: err.response.data.errores ?? 'Acaba de ocurrir un error',
				type: 'error',
			});
		} finally {
			setIsLoaderActive(false);
            setDocsChequed([]);
		}
	}
    function HandleCloseMakeBill() {
        OpenModalSelected("close", "makeBillsByProfessional")
		setModals({ makeBills: false, makeBillsByProfessional: false });
		setDateFilterNew({socialWorkId: null, date: null});
		setProfesionalSelected({profesional: {name: null, value: null}});
		setIdPlanSelected(null);
		setSocialWorksPlans([]);
		setListDocumentsToBeBilled([]);
		setIsSearchDocs(false);
        setDocsChequed([]);
	}

    const searchBillOfProfessional = async () => {
		try {
			setIsLoaderActive(true);

            let mes = moment(dateFilterNew.date).format('MM');
			let anio = moment(dateFilterNew.date).format('YYYY');

			let bodyRequest = {
				obraSocialId: idSocialWork,
				planId: idPlanSelect,
				profesionalId: profesionalSelected.profesional.value,
				mesAnio: `${mes}${anio}`,
			}
			const response = await api.GetDocumentsByProfessional(bodyRequest);

			setListDocumentsToBeBilled(response.data);
			setIsSearchDocs(true);
		} catch (err: any) {
			setDataToast({
				active: true,
				message: err?.response?.data?.errores ?? "Acaba de ocurrir un error",
				type: 'error',
			});
		}
		finally { setIsLoaderActive(false) }
	}
    const getSocialWorksPlan = (event: any) => {
		let idSocialWorkSelected = event?.value;
		if(idSocialWorkSelected !== undefined) {
			api.getSocialWorksPlan(idSocialWorkSelected).then((resp) => {
				let plans = resp.data;
				setSocialWorksPlans(plans);
			});
			setIdSocialWork(idSocialWorkSelected);
		}
		else {
			setSocialWorksPlans([]);
			setIdPlanSelected(null);
		}
	};
    //#endregion

    //#region LISTAR - EDITAR - ELIMINAR - CREAR
    async function LoadInfoForTable(pageSelected: number = 1) {
        try {
            setIsLoaderActive(true);
            const params: ICrudBillFilterParams = {
                obraSocialId: filterObj.obraSocialId,
                fecha: filterObj.fecha,
                estadoFacturaId: filterObj.estadoFacturaId,
                refacturadoId: filterObj.refacturadoId,
                pagina: pageSelected,
                cantidad: loadForPage
            }
            const { status, data } = await GetBills(params);
            if (status === 200) {
                setDataToRender(data?.results ?? []);
                setPagesToRender(data?.quantity ?? 1);
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        }
        finally {
            setIsLoaderActive(false);
        }
    }
    async function OnCreateBill() {
        try {
            OpenModalSelected("close", "create");
            setIsLoaderActive(true);
            const { status } = await CreateBill(values);
            if (status === 200) {
                MessageNotification("Factura creada correctamente.", "success");
                setValues(_DEFAULT_VALUES_BILL.values);
                LoadInfoForTable();
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    async function OnEditBill() {
        try {
            OpenModalSelected("close", "edit");
            setIsLoaderActive(true);
            const { status } = await EditBill(openModal.edit.data.id, values);
            if (status === 200) {
                MessageNotification("Factura editada correctamente.", "success");
                setValues(_DEFAULT_VALUES_BILL.values);
                LoadInfoForTable();
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    async function OnDeleteBill() {
        try {
            OpenModalSelected("close", "delete");
            setIsLoaderActive(true);
            const { status } = await DeleteBill(openModal.delete.data.id);
            if (status === 200) {
                MessageNotification("Factura eliminada correctamente.", "success");
                setValues(_DEFAULT_VALUES_BILL.values);
                LoadInfoForTable();
            }
        } catch (err: any) {
            MessageNotification(err.message, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    async function OnGenerateTxtSW() {
        try {
            OpenModalSelected("close", "generateTxtSW");
            setIsLoaderActive(true);
            const { data, status } = await GenerateTxtSW(openModal.generateTxtSW.data.id, openModal.generateTxtSW.data.obraSocial.id);
            if (status === 200) {
                const titleTxt = `DETALLE - ${openModal.generateTxtSW.data.obraSocial.nombre} - ${openModal.generateTxtSW.data.numeroFactura}`;
                UtilityFNExport("txt", titleTxt, data.results);
            }
        } catch (err: any) {
            const errorMessage = err.message || 'No se pudo obtener el txt.';
            MessageNotification(errorMessage, "error");
        } finally {
            setIsLoaderActive(false);
        }
    }
    //#endregion

    //#region UTILS
    function PrevToAction(e: any, item: any) {
        OpenModalSelected("open", e.currentTarget.name, item);
    }
    function HandleSearch() {
        LoadInfoForTable();
    }
    function OpenModalSelected(action: typeOfActionForModal, modal: typeOfModal, data?: any) {
        const keys = Object.keys(openModal);
        const indexOfKey = Object.keys(openModal).findIndex(item => item === modal);
        const keyOfModalToOpen = keys[indexOfKey];

        //cargar datos
        if (action === "close") {
            setValues(_DEFAULT_VALUES_BILL.values);
        } else {
            if (modal !== "create" && data) {
                setValues({
                    obraSocialId: data.obraSocial.id,
                    planObraSocialId: data.plan.id,
                    fecha: data.fecha,
                    periodo: data.periodo,
                    monto: data.total,
                    profesionales: data.usuarioFactura
                });
            }
        }

        //despues abrir modal
        setOpenModal({ ...openModal, [keyOfModalToOpen]: { show: action === "open", data } });
    }
    function HandleChangePage(event: any, pageSelected: number) {
        setPage(pageSelected);
        LoadInfoForTable(pageSelected);
    }
    function MessageNotification(message: string, type: string) {
        setDataToast({
            active: true,
            message,
            type
        });
    }
    function UtilityFNExport(extension, nameOfFile, responseApi) {
        let fileName = `${nameOfFile}.${extension}`;
        let blob = new Blob([responseApi], {
            type: responseApi.type,
        });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    //#endregion

    const isValidSocialWork = (socialWorkId: number) => validSocialWorks.has(socialWorkId);

    return (
        <>
            <Grid>
                <Grid item style={{marginBottom: 30}}>
                    <Card className='color-border-dash'>
                        <CardContent className='center-responsive-filters'>
                            <Grid className='header-section'>
                                <div className="titleAndVideo">
                                    <span style={{ fontSize: 27, textAlign: "left", lineHeight: "1.03" }}>Facturas</span>
                                </div>
                                <div>
                                    <Button
                                        className='btn-new-filter'
                                        primary
                                        onClick={handleClick}
                                        icon
                                        labelPosition='left'
                                    >
                                        <Icon name='paste' />
                                        Facturar
                                    </Button>
                                    <Menu
                                        anchorEl={menuBills}
                                        open={openMenu}
                                        onClose={() => handleClose(null)}
                                    >
                                        <MenuItem onClick={() => handleClose(billingForm.PorObraSocial)}>Por Obra Social</MenuItem>
                                        <MenuItem onClick={() => handleClose(billingForm.porProfesional)}>Por Profesional</MenuItem>
                                    </Menu>

                                    <Button
                                        className='btn-new-filter'
                                        onClick={() => OpenModalSelected("open", "create")}
                                        icon
                                        labelPosition='left'
                                    >
                                        <Icon name='plus' />
                                        Refacturar
                                    </Button>
                                </div>
                            </Grid>
                            <Grid>
                                <Grid className="row-filterList content-filter-list">
                                    <></>
                                </Grid>
                                <FilterComponents />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {isLoaderActive ? 
                    <Dimmer className="loader-fixed" active inverted>
                        <Loader size="big">Cargando...</Loader>
                    </Dimmer>
                :
                    <Grid container>
                        <TableContainer component={Paper} id='color-letter-table-tasks'>
                        <Table aria-label='spanning table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='letter-title-table c-text-right p-cell'>
                                <b>Número Factura</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-left p-cell'>
                                <b>Obra Social</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-left p-cell'>
                                <b>Plan</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Fecha</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Período</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Total</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Refactura</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-right p-cell'>
                                <b>Recibo</b>
                            </TableCell>
                            <TableCell className='letter-title-table c-text-center p-cell'>
                                <b>Opciones</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToRender.map((item: any) => (
                            <TableRow key={item.id}>
                                <TableCell className='letter-title-table c-text-right p-cell width-numFactura'>
                                    {item.numeroFactura}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-left p-cell'>
                                    {item.obraSocial.nombre}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-left p-cell'>
                                    {item.plan.nombre}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-right p-cell'>
                                    {moment(item.fecha).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-right p-cell'>
                                    {new Date(item.periodo).toLocaleDateString("es-ES", { year: 'numeric', month: 'long' })}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-right p-cell'>
                                    {FormatNumber({ style: 'currency', currency: '$', value: item.total })}
                                </TableCell>
                                <TableCell className='letter-title-table c-text-center p-cell'>
                                    <Checkbox checked={Boolean(item.refacturada)} />
                                </TableCell>
                                <TableCell className='letter-title-table c-text-right p-cell'>
                                    {item.recibo.nombre ?? '--'}
                                </TableCell>
                                <TableCell className='c-text-center'>
                                    <Button.Group>
                                        <ButtonBox buttonToShow='view' name="view" HandleOnClik={(e) => PrevToAction(e, item)} />
                                        {item.refacturada && <ButtonBox buttonToShow='edit' name="edit" HandleOnClik={(e) => PrevToAction(e, item)} />}
                                        <ButtonBox buttonToShow='delete' name="delete" HandleOnClik={(e) => PrevToAction(e, item)} />
                                        {isValidSocialWork(item.obraSocial.id) && !item.refacturada &&
                                            <ButtonBox buttonToShow='generateTxtSW' name='generateTxtSW' HandleOnClik={(e) => PrevToAction(e, item)} />
                                        }
                                    </Button.Group>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                        </TableContainer>
                    </Grid>
                }

                {dataToRender && dataToRender.length === 0 &&
                    <Grid>
                        <TableContainer component={Paper} id='color-letter-table-tasks' style={{padding: 14}}>
                                <h5 style={{textAlign: "center"}}>No se encontraron resultados.</h5>
                        </TableContainer>
                    </Grid>
                }

                {dataToRender && 
                    <Grid container justifyContent='center' style={{marginTop: 10}}>
                        <Pagination
                            className="pagination"
                            count={pagesToRender}
                            page={page}
                            onChange={HandleChangePage}
                        />
                    </Grid>
                }
            </Grid>

            {/* MODALS */}
            {openModal.create.show &&
                <NewModalBill
                    titleOfModal="Nuevo"
                    openModal={openModal}
                    values={values}
                    socialWorkList={socialWorkList}
                    isEdit={false}
                    setOpenModal={setOpenModal}
                    setValues={setValues}
                    MessageNotification={MessageNotification}
                    HandleCloseModal={() => OpenModalSelected("close", "create")}
                    SendValues={OnCreateBill} />
            }
            {openModal.edit.show &&
                <NewModalBill
                    titleOfModal="Editar"
                    openModal={openModal}
                    values={values}
                    socialWorkList={socialWorkList}
                    isEdit={true}
                    setOpenModal={setOpenModal}
                    setValues={setValues}
                    MessageNotification={MessageNotification}
                    HandleCloseModal={() => OpenModalSelected("close", "edit")}
                    SendValues={OnEditBill} />
            }
            {openModal.view.show &&
                <ViewProfesionalList
                    title="Profesionales"
                    openModal={openModal.view.show}
                    aditionalData={openModal.view.data}
                    MessageNotification={MessageNotification}
                    HandleCloseModal={() => OpenModalSelected("close", "view")}
                />
            }
            {openModal.delete.show &&
                <GeneralModalDelete
                    contentText={`¿Está seguro de eliminar la factura N°: ${openModal.delete.data.numeroFactura}?`}
                    openModal={openModal.delete.show}
                    HandleClose={() => OpenModalSelected("close", "delete")}
                    HandleSubmit={OnDeleteBill}
                    warningText={openModal.delete.data.refacturada ? '' : 'Al confirmar, el monto de las practicas se actualizará según corresponda.'}
                />
            }
            {openModal.generateTxtSW.show &&
                <GeneralModalCrud
                    titleOfModal={"Generar Reporte"}
                    children={`Estas por descargar un reporte para ${openModal.generateTxtSW.data.obraSocial.nombre}`}
                    openModal={openModal.generateTxtSW.show}
                    HandleCloseModal={() => OpenModalSelected("close", "generateTxtSW")}
                    HandleSubmit={OnGenerateTxtSW}
                />
            }
            {modals.makeBills &&
				<GeneralModalCrud
					titleOfModal="Facturar"
					openModal={true}
					HandleSubmit={HandleEnvoiced}
					HandleCloseModal={HandleCloseMakeBill}
					styles="cont-default-modal"
					textSubmit="Confirmar"
				>
					<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
						<div style={{ marginLeft: 5, display: 'flex', alignItems: 'center' }}>
							<div style={{ minWidth: 258, marginRight: 6 }}>
								<ReactSelect
									placeholder='Obra Social...'
									loading={loaderSelect.socialWork}
									options={socialWorkList}
									isClearable={true}
									onChange={(e: any) => setDateFilterNew(preventStatus => ({ ...preventStatus, socialWorkId: e?.value ?? null }))}
								/>
							</div>
							<Tooltip title='Factura según la Obra Social Seleccionada (opcional)'>
								<HelpIcon name='help' color="action" fontSize="large" />
							</Tooltip>
						</div>
						<KeyboardDatePicker
							style={{ margin: 5 }}
							autoOk
							className='size-date-picke-filter'
							inputVariant='outlined'
							InputAdornmentProps={{ position: 'end' }}
							variant='inline'
							name='month'
							openTo='month'
							views={['month', 'year']}
							placeholder='Mes - Año'
							value={dateFilterNew.date}
							onChange={(date) => setDateFilterNew({ ...dateFilterNew, date: date })}
						/>
					</MuiPickersUtilsProvider>
				</GeneralModalCrud>
            }
            {openModal.makeBillsByProfessional.show &&
				<BillByProfessionalModal
					HandleEnvoicedByProfessional={HandleEnvoicedByProfessional}
					HandleCloseMakeBill={HandleCloseMakeBill}
					loadingSelect={loaderSelect}
					socialWorks={socialWorkList}
					getSocialWorksPlan={getSocialWorksPlan}
					nameSocialWorkPlans={nameSocialWorkPlans}
					setIdPlanSelected={setIdPlanSelected}
					setDateFilterNew={setDateFilterNew}
					dateFilterNew={dateFilterNew}
					profesionalList={profesionalList}
					setProfesionalSelected={setProfesionalSelected}
					IsSearchBillEnabled={IsSearchBillEnabled}
					searchBillOfProfessional={searchBillOfProfessional}
					listDocumentsToBeBilled={listDocumentsToBeBilled}
					isSearchDocs={isSearchDocs}
					IsAcceptEnabled={IsAcceptEnabled}
					setDocsChequed={setDocsChequed}
					docsChequed={docsChequed}
                    esViewInvoices={true}
				/>
			}
        </>
    )
}

export default CrudBill;