import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import React from 'react';
import ReactSelect from "react-select";
import { Button } from "semantic-ui-react";
import api from '../../../api';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Toast from "../../common/Toast";
import { saveAs } from "file-saver";

const defaultValues = {
  obraSocial: { id: 0, nombre: 'Todas' },
  esPorPaciente: true
}

const useStyles = makeStyles({
  radio: {
    color: "#777777",
    "&.Mui-checked": {
      color: "#ef4f5b",
    },
  },
});

export default function DownloadPeriodModal({
  openModal,
  setOpenDownloadModal,
  dataToDownloadModal,
  setIsLoading
}) {
  const classes = useStyles();
  const [dataToast, setDataToast] = React.useState({ active: false, message: '', type: '' });
  const [values, setValues] = React.useState(defaultValues);
  const [socialWorkList, setSocialWorkList] = React.useState([]);

  React.useEffect(() => {
    GetSocialWorks();
  }, []);

  async function GetSocialWorks() {
    try {
      const resp = await api.GetSocialWorkForSelect();
      const allOption = { id: 0, nombre: 'Todas' };

      setSocialWorkList([allOption, ...resp.data]);
    } catch (err) {
      MessageNotification(err?.response?.data?.errores, "error");
    }
  }

  const handleOrdenChange = (event) => {
    const esOrden = event.target.value === 'paciente';
    setValues((prevValues) => ({
      ...prevValues,
      esPorPaciente: esOrden
    }));
  };

  function OnClickGenerate() {
    const request = {
      professionalId: dataToDownloadModal.profesionalId,
      id: dataToDownloadModal.id,
      ordenearPorFechaSesion: !values.esPorPaciente,
      obraSocialId: values.obraSocial.id === 0 ? null : values.obraSocial.id,
    };

    const dataToFileName = {
      mes: dataToDownloadModal.mes,
      anio: dataToDownloadModal.anio,
      profesionalNombre: dataToDownloadModal.profesionalNombre
    };

    exportExcelPatients(request, dataToFileName);
  }

  const exportExcelPatients = async (request, dataToFileName) => {
    setIsLoading(true);
    try {
      const res = await api.exportExcelByProfessionalId(request);

      let extension = 'xlsx';
      let tempFileName = `Presentacion de Ordenes -- ${dataToFileName.mes}-${dataToFileName.anio} -- ${dataToFileName.profesionalNombre}`;
      let fileName = `${tempFileName}.${extension}`;

      let blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel',
      });
      saveAs(blob, fileName);

      setDataToast({
        active: true,
        message: 'Exportado correctamente',
        type: 'success',
      });
    } catch (error) {
      let errorMessage = "Acaba de ocurrir un error";

      try {
        const errorText = await error.response.data.text();
        const parsedError = JSON.parse(errorText);
        errorMessage = parsedError?.errores || errorMessage;
      } catch (parseError) {
        errorMessage = error.response?.data?.errores || errorMessage;
      }

      setDataToast({
        active: true,
        message: errorMessage,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  function HandleCloseModal() {
    setValues(defaultValues);
    setOpenDownloadModal(false);
  }

  function MessageNotification(message, type) {
    setDataToast({
      active: true,
      message: message || "Acaba de ocurrir un error.",
      type: type,
    });
  }
  return (
    <>
      <Dialog
        open={openModal}
        onClose={HandleCloseModal}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Descargar Excel
        </DialogTitle>
        <DialogContent style={{ overflowY: "visible" }} className="cont-default-modal">
          {/* Sección de "Obras Sociales" */}
          <div style={{ position: "relative" }}>
            <label htmlFor="my-select" className="customLabelSelect">Obra Social:</label>
            <ReactSelect
              placeholder='Obra Social...'
              options={socialWorkList}
              getOptionLabel={(option) => option.nombre}
              getOptionValue={(option) => option.id}
              onChange={(e) => setValues({ ...values, obraSocial: { id: e.id, nombre: e.nombre } })}
              value={values.obraSocial}
              styles={{
                control: (s) => ({ ...s, maxWidth: 300 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
            />
          </div>

          {/* Sección de "Orden" */}
          <FormControl component="fieldset">
            <FormLabel component="legend">Ordenar por</FormLabel>
            <RadioGroup
              aria-label="direccion"
              name="direccion"
              value={values.esPorPaciente ? 'paciente' : 'nroAutorizacion'}
              onChange={handleOrdenChange}
              row
              style={{ justifyContent: "space-evenly" }}
            >
              <FormControlLabel
                value="paciente"
                control={<Radio classes={{ root: classes.radio, checked: classes.radio }} />}
                label="Paciente"
              />
              <FormControlLabel
                value="nroAutorizacion"
                control={<Radio classes={{ root: classes.radio, checked: classes.radio }} />}
                label="N° Autorización"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={HandleCloseModal}>
            Cancelar
          </Button>
          <Button onClick={() => OnClickGenerate()} color='blue'>
            Generar
          </Button>
        </DialogActions>
        {dataToast.active && (
          <Toast dataToast={dataToast} setDataToast={setDataToast} />
        )}
      </Dialog>
    </>
  );
}