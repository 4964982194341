import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import { Button, Card, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import api from "../../../api";
import { Options, TypeRole } from "../../../enums";
import UsersList from "./UsersList";

export default function Users({ setDataToast }) {
  const history = useHistory();
  const [openCountUsers, setOpenCountUsers] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [dataTable, setDataTable] = useState();
  const loadForPage = 20;
  const [viewDataPreDelete, setViewDataPreDelete] = useState({});
  const [alertDelete, setAlertDelete] = useState(false);
  const [quantityData, setQuantityData] = useState(1);
  const [pageTable, setPageTable] = useState(1);
  const refInput = useRef();
  const [regionals, setRegionals] = useState([]);
  const [quantityUsersActive, setQuantityUsersActive] = useState(0);
  const [allProfesionals, setAllProfesionals] = useState([]);
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [dateFilterNew, setDateFilterNew] = useState(null);
  const [loadingSelect, setLoadingSelect] = useState({
    rol: false,
    users: false,
    account: false,
    association: false,
  });
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [accountSelected, setAccountSelected] = useState(null);
  const [associationSelected, setAssociationSelected] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [associationList, setAssociationList] = useState([]);
  const [filterObj, setFilterObj] = useState({
    professional: null,
    cuentaId: null,
    isRemoved: false,
    isRemovedChecked: false,
  });
  const { setValue } = useForm({
    defaultValues: {
      professional: "",
      cuentaId: "",
      name: "",
      lastname: "",
      enrollment: "",
      password: "",
      cuit: "",
      idRol: "",
      email: "",
      sexo: "",
      cbu: "",
    },
  });
  const roleLoggead = JSON.parse(localStorage.getItem("TypeRole"));

  useEffect(() => {
    let options = JSON.parse(localStorage.getItem("optionConfig_User"));
    options.forEach((element) => {
      if (element == Options.isCreate) {
        setCreate(true);
      } else if (element == Options.isEdit) {
        setEdit(true);
      } else if (element == Options.isDelete) {
        setDelete(true);
      }
    });

    roleLoggead === TypeRole.SuperAdmin ? setSuperAdmin(true) : setSuperAdmin(false);
    roleLoggead === TypeRole.Admin ? setAdmin(true) : setAdmin(false);

    if(roleLoggead === TypeRole.Colegio) {
      getRegionalesPorRol();
    }
  }, []);

  useEffect(() => {
    setFilterObj((prev) => ({ ...prev, cuentaId: null }));
    localStorage.removeItem("regionalFilter");
  }, []);

  useEffect(() => {
    const savedRegionalFilter = localStorage.getItem("regionalFilter");
    if (savedRegionalFilter) {
      setFilterObj((prev) => ({
        ...prev,
        cuentaId: JSON.parse(savedRegionalFilter),
      }));
    }
  }, []);

  const handleRegionalFilterChange = (selectedOption) => {
    const selectedRegionalId = selectedOption?.id || null;
    setFilterObj((prev) => ({
      ...prev,
      cuentaId: selectedRegionalId,
    }));
    if (selectedRegionalId) {
      localStorage.setItem(
        "regionalFilter",
        JSON.stringify(selectedRegionalId)
      );
    } else {
      localStorage.removeItem("regionalFilter");
    }
  };

  useEffect(() => {
    const savedRegionalFilter = localStorage.getItem("regionalFilter");
    let objFitlerSave = {
      profesional: filterObj.professional,
      pagina: pageTable,
      cantidad: loadForPage,
      conPaginacion: true,
      dadosDeBaja: filterObj.isRemoved,
      cuentaId: savedRegionalFilter ? JSON.parse(savedRegionalFilter) : null,
    };
    loadData(objFitlerSave);
  }, [pageTable]);

  const handleClickOpen = () => {
    history.push("/configuraciones/usuarios/crear");
  };
  const handleClickOpenCheckIn = () => {
    setDateFilterNew(null);
    setOpenCheckIn(true);
    getAssociationsCombo();
    getAccountComboList();
  };

  const getRegionalesPorRol = async () => {
      let colegioId = JSON.parse(localStorage.getItem("colegioId"));
      try {
        setLoadingActive(true);
        let resp = await api.getRegionals(colegioId);
        let auditorRegionalsArray = resp.data;

        for (let i = 0; i < auditorRegionalsArray.length; i++) {
          auditorRegionalsArray[i].name = auditorRegionalsArray[i]["nombre"];
          delete auditorRegionalsArray[i].nombre;
        }
        setRegionals(auditorRegionalsArray);
        setLoadingActive(false);
      } catch (err) {
        setLoadingActive(false);
        setDataToast({
          active: true,
          message: "Acaba de ocurrir un error",
          type: "error",
        });
      }
  };

  const getAccountComboList = () => {
    setLoadingSelect({
      ...loadingSelect,
      account: true,
    });
    api
      .getAccountsUsersList()
      .then((res) => {
        let accountList = res.data.map((item) => {
          return { value: item.id, label: item.nombre };
        });
        setAccountList(accountList);
      })
      .catch((err) => {
        shortError(err.response.data.errores || "Ocurrió un error.");
      })
      .finally(() => {
        setLoadingSelect({
          ...loadingSelect,
          account: false,
        });
      });
  };
  const getAssociationsCombo = () => {
    setLoadingSelect({
      ...loadingSelect,
      association: true,
    });
    api
      .GetAllAssociations()
      .then((resp) => {
        let associationList = resp.data.map((item) => {
          return { value: item.id, label: item.nombre };
        });
        setAssociationList(associationList);
      })
      .catch((err) => {
        shortError(err.response.data.errores || "Ocurrió un error.");
      })
      .finally(() => {
        setLoadingSelect({
          ...loadingSelect,
          association: false,
        });
      });
  };
  const getAccount = (event) => {
    if (event !== null) {
      setAccountSelected(event.value);
    } else {
      setAccountSelected(null);
    }
  };
  const getAssociation = (event) => {
    if (event !== null) {
      setAssociationSelected(event.value);
    } else {
      setAssociationSelected(null);
    }
  };
  const shortError = (message) => {
    setDataToast({
      active: true,
      message: message,
      type: "error",
    });
  };

  const onClickViewList = (pageSelected = 1) => {
    setPageTable(pageSelected);
    setLoadingActive(true);
    let filters = {};
    if (filterObj.professional) {
      filters.profesional = filterObj.professional;
    }

    if (filterObj.cuentaId) {
      filters.cuentaId = filterObj.cuentaId;
    }

    filters.conPaginacion = true;
    filters.pagina = pageSelected;
    filters.cantidad = loadForPage;
    filters.dadosDeBaja = filterObj.isRemovedChecked;

    setFilterObj({ ...filterObj, isRemoved: filterObj.isRemovedChecked });

    loadData(filters);
  };

  const loadData = (filters) => {
    setLoadingActive(true);
    api
      .getUsersList(filters)
      .then((resp) => {
        let list = resp.data.resultados.map((item) => {
          return {
            id: item.id,
            email: item.email,
            nombre: item.nombre,
            apellido: item.apellido,
            rol: {
              id: item.rol.id,
              nombre: item.rol.nombre,
            },
            matricula: item.matricula,
            obraSocial: {
              id: item.obraSocial?.id,
              nombre: item.obraSocial?.nombre,
            },
            cuit: item.cuit,
            sexo: item.sexo,
            cuenta: {
              id: item.cuenta?.id,
              nombre: item.cuenta?.nombre,
            },
            colegio: {
              id: item.colegio?.id,
              nombre: item.colegio?.nombre,
            },
            administradoPorRegional: item.administradoPorRegional,
            trabajaConObraSocial: item.trabajaConObraSocial,
            noSocio: item.noSocio,
            fechaNacimiento: item.fechaNacimiento,
            cbu: item.cbu,
            fechaUltimaConexion: item.fechaUltimaConexion,
          };
        });
        if (list.length > 0)
          setQuantityUsersActive(resp.data.cantidadTotalRegistros);
        setDataTable(list);
        setQuantityData(resp.data.cantidadPaginas);
      })
      .catch((err) => {
        setDataToast({
          active: true,
          message: err?.response?.data?.errores || "Ocurrio un error",
          type: "error",
        });
      })
      .finally(() => {
        setLoadingActive(false);
      });
  };
  const OnClickAccept = () => {
    if (!dateFilterNew) {
      shortError("Mes - Año, campo requerido");
      return;
    }

    if (!associationSelected && !accountSelected) {
      shortError("Regionales y/o Colegios, no pueden estar vacíos.");
      return;
    }

    setLoadingActive(true);
    let body = {
      mes: parseInt(moment(dateFilterNew).format("MM")),
      anio: parseInt(moment(dateFilterNew).format("YYYY")),
      cuentaId: accountSelected,
      colegioId: associationSelected,
    };
    api.getUsersProfessional(body).then((res) => {
      setLoadingActive(false);
      setAllProfesionals(res.data);
      setOpenCountUsers(true);
    });
  };
  function HandleCloseBilling() {
    setAccountSelected(null);
    setAssociationSelected(null);
    setOpenCheckIn(false);
  }
  const sendEmail = () => {
    setOpenCountUsers(false);
    setOpenCheckIn(false);
    setLoadingActive(true);
    let body = {
      periodo: dateFilterNew,
      infoFacturar: allProfesionals,
      colegioId: associationSelected,
      cuentaId: accountSelected,
    };
    api
      .sendEmailToRegional(body)
      .then((resp) => {
        setLoadingActive(false);
        setAccountSelected(null);
        setAssociationSelected(null);
        setDataToast({
          active: true,
          message: "Email enviado correctamente.",
          type: "success",
        });
      })
      .catch((err) => {
        setLoadingActive(false);
        setDataToast({
          active: true,
          message: err?.response?.data?.errores || "Ocurrió un error",
          type: "error",
        });
      });
  };

  return (
    <>
      <Grid>
        {loadingActive ? (
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando..</Loader>
          </Dimmer>
        ) : (
          <Grid.Row>
            <Card className="color-border-dash">
              <CardContent className="center-responsive-filters">
                <Grid.Row className="header-section">
                  <div className="titleAndVideo">
                    {(isAdmin || isSuperAdmin) && (
                      <Fab
                        disabled
                        style={{
                          width: 50,
                          height: 50,
                          marginTop: -8,
                        }}
                      >
                        <Badge
                          badgeContent={quantityUsersActive}
                          max={999}
                          color="primary"
                        ></Badge>
                      </Fab>
                    )}
                    <span style={{ fontSize: 27 }}>Usuarios</span>
                  </div>
                  <Grid.Row className="contBtnHeaderSection">
                    {isCreate == true && (
                      <Button
                        className="btn-new-filter"
                        onClick={handleClickOpen}
                        icon
                        labelPosition="left"
                      >
                        <Icon name="plus" />
                        Nuevo
                      </Button>
                    )}
                    {isSuperAdmin == true && (
                      <Button
                        className="btn-filter-fact"
                        primary
                        onClick={handleClickOpenCheckIn}
                        icon
                        labelPosition="left"
                      >
                        <Icon name="file alternate" />
                        Facturar
                      </Button>
                    )}
                  </Grid.Row>
                </Grid.Row>
                <Grid.Row className="content-filter-list">
                  <TextField
                    className="filter-input"
                    size="small"
                    id="nombre"
                    label="Profesional"
                    value={filterObj.professional}
                    onChange={(e) => {
                      setFilterObj({
                        ...filterObj,
                        professional: e.target.value,
                      });
                    }}
                    variant="outlined"
                  />
                  {roleLoggead === TypeRole.Colegio &&
                    
                  <ReactSelect
                    className="filter-input"
                    placeholder="Regional..."
                    loading={loadingActive}
                    options={regionals}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={
                      regionals.find((r) => r.id === filterObj.cuentaId) ||
                      null
                    }
                    onChange={handleRegionalFilterChange}
                    isClearable
                    styles={{
                      control: (s) => ({ ...s, maxWidth: 350 }),
                    }}
                  />

                  }

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterObj.isRemovedChecked}
                        onChange={(e) => {
                          setFilterObj({
                            ...filterObj,
                            isRemovedChecked: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Dadas de baja"
                  />
                </Grid.Row>
                <Button
                  className="btn-search-filter"
                  primary
                  icon
                  labelPosition="left"
                  onClick={() => onClickViewList()}
                  type="submit"
                >
                  <Icon name="search" />
                  Buscar
                </Button>
              </CardContent>
            </Card>
          </Grid.Row>
        )}
        {loadingActive ? (
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando..</Loader>
          </Dimmer>
        ) : (
          <UsersList
            dataTable={dataTable}
            isEdit={isEdit}
            isDelete={isDelete}
            quantityData={quantityData}
            setDataToast={setDataToast}
            page={pageTable}
            setPage={setPageTable}
            viewDataPreDelete={viewDataPreDelete}
            setViewDataPreDelete={setViewDataPreDelete}
            onClickViewList={onClickViewList}
            alertDelete={alertDelete}
            setAlertDelete={setAlertDelete}
            isRemoved={filterObj.isRemoved}
          />
        )}
      </Grid>

      <Dialog open={openCheckIn} onClose={HandleCloseBilling}>
        <DialogTitle id="form-dialog-title">Facturar Cuenta</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <div style={{ display: "flex" }}>
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <>
                <KeyboardDatePicker
                  style={{ marginTop: 5 }}
                  autoOk
                  className="size-date-picke-filter"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: "end" }}
                  variant="inline"
                  name="month"
                  openTo="month"
                  views={["month", "year"]}
                  placeholder="Mes - Año."
                  value={dateFilterNew}
                  onChange={(date) => setDateFilterNew(date)}
                />
                {dateFilterNew && (
                  <Icon
                    className="icon-clear-date-filter"
                    style={{ marginTop: 10 }}
                    name="close"
                    size="large"
                    onClick={() => setDateFilterNew(null)}
                  />
                )}
              </>
            </MuiPickersUtilsProvider>
          </div>
          <ReactSelect
            placeholder="Colegios..."
            loading={loadingSelect.account}
            options={associationList}
            onChange={getAssociation}
          />
          <ReactSelect
            placeholder="Regionales..."
            isLoading={loadingSelect.account}
            options={accountList}
            onChange={getAccount}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={HandleCloseBilling}>Cancelar</Button>
          <Button onClick={OnClickAccept} color="green">
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCountUsers} onClose={() => setOpenCountUsers(false)}>
        <DialogContent>
          {allProfesionals.length === 0 ? (
            <DialogContentText id="alert-dialog-description">
              <Typography>
                No hay registros disponibles para los datos seleccionados.
              </Typography>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <Typography>Cantidad de profesionales por regional.</Typography>
              <ul ref={refInput}>
                {allProfesionals.map((item) => (
                  <li style={{ margin: 4 }}>
                    <p>
                      {item.nombreRegional}: {item.cantidadDocumentos}
                    </p>
                  </li>
                ))}
              </ul>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setOpenCountUsers(false)}>Cerrar</Button>
          <Button
            disabled={allProfesionals.length === 0}
            onClick={sendEmail}
            color="green"
          >
            Enviar Email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
